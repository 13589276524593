import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Press = () => (
  <Layout>
    <SEO title="press" />
    <main className="main press-feed">
    <div>
        <h1>Awards</h1>
        <br/>
        <a target="_blank" href="https://techplaymakerawards.com/categories/rising-technologist">
            <div className="press-card">
                <div className="press-image press-booking"></div>
                <div>
                    <h4>Rising Technologist Finalist - Booking.com Tech Playmaker Award 2020</h4>
                    <br/>
                    <p className="post-card-footer">February 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://www.jpost.com/Israel-News/Nefesh-BNefesh-awards-twelve-projects-by-olim-with-grants-and-support-581387">
            <div className="press-card">
                <div className="press-image press-izi"></div>
                <div>
                    <h4>IZI grant recipient</h4>
                    <br/>
                    <p className="post-card-footer">February 2019</p>
                </div>
            </div>
        </a>
    </div>
    <br/>
    <div>
        <h1>Features</h1>
        <br/>
        <a target="_blank" href="https://www.lemonade.com/blog/covered-by-lemonade/">
            <div className="press-card">
                <div className="press-image press-lemonade"></div>
                <div className="press-details">
                    <h4>The Story of #CoveredByLemonade (mention)</h4>
                    <br/>
                    <p className="post-card-footer">September 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://open.spotify.com/episode/1Wh6SZT3xYSQLD3mXK7FUA?si=eDrppjYDSPaQKnhSXNs7jQ">
            <div className="press-card">
                <div className="press-image press-whatshouldi"></div>
                <div className="press-details">
                    <h4>What Should I Do With My Life? Episode 4: Eden Adler (podcast)</h4>
                    <br/>
                    <p className="post-card-footer">August 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://open.spotify.com/episode/1TdLItT0k6ThGaeoDbkQWn?si=9t3CSlTCQz-xPqf75ATxNQ">
            <div className="press-card">
                <div className="press-image press-theonlyway"></div>
                <div className="press-details">
                    <h4>The Only Way Is Up: Eden Adler - Inspiring more women to work in tech (podcast)</h4>
                    <br/>
                    <p className="post-card-footer">July 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://www.techgirl.nl/eden-adler-dingen-googelen-is-geen-valsspelen/">
            <div className="press-card">
                <div className="press-image press-techgirlnl"></div>
                <div className="press-details">
                    <h4>Tech Girl Netherlands Blog Feature: Eden Adler</h4>
                    <br/>
                    <p className="post-card-footer">June 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://codingblonde.com/changing-careers-and-getting-into-tech-experience-of-3-self-taught-programmers/">
            <div className="press-card">
                <div className="press-image press-youtube"></div>
                <div className="press-details">
                    <h4>Changing Careers: Getting into Tech - Tips from Self Taught Programmers (YouTube)</h4>
                    <br/>
                    <p className="post-card-footer">May 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://codingblonde.com/eden-adler/">
            <div className="press-card">
                <div className="press-image press-codingblonde"></div>
                <div className="press-details">
                    <h4>Coding Blonde - Women in Tech Weekly Feature: Eden</h4>
                    <br/>
                    <p className="post-card-footer">December 2019</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://podcasts.apple.com/us/podcast/episode-10-season-finale-eden-software-engineer-lemonade/id1457747094?i=1000450779906">
            <div className="press-card">
                <div className="press-image press-hummus"></div>
                <div className="press-details">
                    <h4>Hummus and Tech: Episode 10 Season Finale with Eden, Software Engineer @ Lemonade (Podcast)</h4>
                    <br/>
                    <p className="post-card-footer">September 2019</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://astoldbywomen.com/eden-adler-let-your-curiosity-lead-you/">
            <div className="press-card">
                <div className="press-image press-astoldbywomen"></div>
                <div className="press-details">
                    <h4>As Told By Women: Let your curiosity lead you with Eden Adler</h4>
                    <br/>
                    <p className="post-card-footer">August 2019</p>
                </div>
            </div>
        </a>
    </div>
    <br/>
    <div>
        <h1>Speaking Engagements</h1>
        <br/>
        <a target="_blank" href="https://www.youtube.com/watch?v=Zm0MXx0g8xs">
            <div className="press-card">
                <div className="press-image press-entwine"></div>
                <div className="press-details">
                    <h4>Entwine Exclusive with Michael Hartal, Interviewed by Eden Adler</h4>
                    <br/>
                    <p className="post-card-footer">May 2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://www.eventbrite.com/e/next-israel-networking-event-tickets-75132024909#">
            <div className="press-card">
                <div className="press-image press-nbn"></div>
                <div className="press-details">
                    <h4>Panelist at Next: Israel Networking Event</h4>
                    <br/>
                    <p>Flown to NY to be one of three panelists at career and networking event</p>
                    <br/>
                    <p className="post-card-footer">November 2019</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="">
            <div className="press-card">
                <div className="press-image press-itc"></div>
                <div className="press-details">
                    <h4>Speaking to Israel Tech Challenge Coding Bootcamp (biannual engagement)</h4>
                    <br/>
                    <p className="post-card-footer">2018-2020</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://www.meetup.com/Le-Wagon-TLV-Coding-Bootcamp/events/261974682/">
            <div className="press-card">
                <div className="press-image press-lewagon"></div>
                <div className="press-details">
                    <h4>Speaking to Le Wagon Coding Bootcamp</h4>
                    <br/>
                    <p className="post-card-footer">June 2019</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://www.facebook.com/UMSLC/photos/10156839747066187">
            <div className="press-card">
                <div className="press-image press-slc"></div>
                <div className="press-details">
                    <h4>University of Michigan Science Learning Center 30th anniversary alumni speaker</h4>
                    <br/>
                    <p className="post-card-footer">May 2019</p>
                </div>
            </div>
        </a>
    </div>
    </main>
  </Layout>
)

export default Press